import React from "react";

function ProfileSubscribedPlans({ credits }) {
  return (
    <div className="my-4" id="subscribed-plans">
      <div className="row">
        <div className="col-lg-3 col-md-4 label ">Remaining Credits</div>
        <div className="col-lg-9 col-md-8">{credits}</div>
      </div>
      {/* <table className="table ">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Plan</th>
            <th scope="col">Remaining Credits</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>All Plans Together</td>
            <td>{credits}</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}

export default ProfileSubscribedPlans;
