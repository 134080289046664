import React from "react";
import MainAboutSection from "./homepage-section-components/MainAboutSection";
import MainCountSection from "./homepage-section-components/MainCountSection";
import MainFeatureSection from "./homepage-section-components/MainFeatureSection";
import MainTeamSection from "./homepage-section-components/MainTeamSection";
import MainPricingSection from "./homepage-section-components/MainPricingSection";
import MainFAQSection from "./homepage-section-components/MainFAQSection";
import MainContactSection from "./homepage-section-components/MainContactSection";
import BuyReportIntro from "./subcomponents/BuyReportIntro";
import AuditServiceProviderIntro from "./subcomponents/AuditServiceProviderIntro";
import PlanNewAuditIntro from "./subcomponents/PlanNewAuditIntro";
import WhyGMPSection from "./homepage-section-components/WhyGMPSection";
function MainSection() {
  return (
    <main
      id="main"
      style={{ background: "url(assets/img/gmp-white-bg.svg)" }}
    >
      {/* <!-- ======= About Section ======= --> */}
      {/* Main About Section */}
      {/* <MainAboutSection /> */}
      {/* <!-- End About Section --> */}

      {/* <!-- ======= Features Section ======= --> */}
      {/* Main Feature Section */}
      {/* <MainFeatureSection /> */}
      {/* <!-- End Features Section --> */}

      {/* <!-- ======= Counts Section ======= --> */}
      {/* Main Counts Section */}
      {/* <MainCountSection /> */}
      {/* <!-- End Counts Section --> */}

      <BuyReportIntro />
      <AuditServiceProviderIntro />
      <PlanNewAuditIntro />

      {/* <!-- ======= Details Section ======= --> */}
      {/* <MainDetailSection /> */}
      {/* <!-- End Details Section --> */}

      {/* <!-- ======= Gallery Section ======= --> */}
      {/* Main Gallery Section */}
      {/* <MainGallerySection /> */}
      {/* <!-- End Gallery Section --> */}

      {/* <!-- ======= Testimonials Section ======= --> */}
      {/* Main Testing monial section */}
      {/* <MainTestimonialSection /> */}
      {/* <!-- End Testimonials Section --> */}

      {/* <!-- ======= Team Section ======= --> */}
      {/* Main Team Section */}

      {/* <!-- End Team Section --> */}

      {/* <!-- ======= Pricing Section ======= --> */}
      {/* Main Pricing Section */}
      {/* <MainPricingSection /> */}
      {/* <!-- End Pricing Section --> */}

      <WhyGMPSection />
      {/* <!-- ======= F.A.Q Section ======= --> */}
      {/* Main FAQ Section */}
      <MainFAQSection />
      {/* <!-- End F.A.Q Section --> */}
      {/* <MainTeamSection /> */}

      {/* <!-- ======= Contact Section ======= --> */}
      {/* Main Contact Section */}
      <MainContactSection />
      {/* <!-- End Contact Section --> */}
    </main>
  );
}

export default MainSection;
