import React, { useState, useEffect } from "react";
import { fetchallmyitems } from "../../services/item";
import { toast } from "react-toastify";
import { downloadCurrentUserFileService } from "../../services/fileutility";
import formatDate from "../../utils/dateUtility";
import { updateItem, deleteItemById } from "../../services/item";
import countries from "../../data/Countries";

function PublishedAuditReports({ publishedReportCount }) {
  const itemsPerPage = 5; // Number of items to display per page

  const [tableData, setTableData] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchallmyitems()
      .then((data) => {
        setTableData(data); // Update the state with fetched data
      })
      .catch((error) => {
        toast.error("Error fetching audit reports:", error);
      });
  }, [publishedReportCount, editData]);

  

  // Calculate total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const downloadReport = async () => {
    try {
      // Call your downloadCurrentUserFileService function to get the response
      const response = await downloadCurrentUserFileService("report");

      // Check if the response contains a valid URL
      if (response.status && response.data) {
        const downloadUrl = response.data;

        // Open the download URL in a new tab or window
        window.open(downloadUrl, "_blank");
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  const handleEdit = (item) => {
    setEditItemId(item.id);
    setEditData({ ...item });
    setShowModal(true);

    // if (editItemId !== itemId) {
    //   setEditData({ ...tableData.find((item) => item.id === itemId) });
    // }
    // // Set the editItemId state to the ID of the item being edited
    // setEditItemId(itemId);
  };
  const handleSave = () => {

    // make api call to update the saved data
    // updateItem(editData, editItemId)
    //   .then(() => {
    //     toast.success("Report Updated Successfully");
    //     setEditItemId(null);
    //     setEditData({});
    //   })
    //   .catch((error) => {
    //     toast.error(error);
    //   });
 // below snippet added for data save after adding popup model
      updateItem(editData, editItemId)
      .then(() => {
        toast.success("Report Updated Successfully");
        setTableData((prevData) =>
          prevData.map((item) =>
            item.id === editItemId ? { ...item, ...editData } : item
          )
        );
        setEditItemId(null);
        setEditData({});
        setShowModal(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDelete = (editItemId) => {
    //make api call to delete the item
    editItemId &&
      deleteItemById(editItemId)
        .then(() => {
          toast.success("Report Deleted Successfully");
          setEditData({});
        })
        .catch((error) => {
          toast.error(error);
        });
  };

  const handleChange = (attribute, value) => {
    // Update the editData with the new value for the given attribute
    setEditData((prevEditData) => ({
      ...prevEditData,
      [attribute]: value,
    }));
  };

  return (
    <>
      <div className="card">
        <div id="registeredvendorstable" className="card-body p-3 0 my-3 mx-2">
          <h5 className="card-title">Published Audit Reports</h5>

          {tableData.length !== 0 ? (
            <table
              id="table"
              className="table table-responsive table-hover caption-top box-shadow-border-radius"
            >
              <thead>
                <tr>
                  <th scope="col" className="col-1 text-center">
                    S. No.
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Product Name
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Manufacturer Name
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Unit Number
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Location
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Country
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Date of Audit
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Report Valid Till
                  </th>
                  {/* <th scope="col" className="col-1 text-center">
                    Sample Audit Report
                  </th> */}
                  <th scope="col" className="col-2 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={item.id}>
                    <td scope="row" className="col-1 text-center">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="col-1 text-center">
                      {editItemId === item.id ? (
                        // Render editable content if the item is in edit mode
                        <input
                          type="text"
                          value={editData.productName || item.productName}
                          onChange={(e) => {
                            handleChange("productName", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        // Render regular content if the item is not in edit mode
                        item.productName
                      )}
                    </td>
                    <td className="col-2 text-center">
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={
                            editData.manufacturerName || item.manufacturerName
                          }
                          onChange={(e) => {
                            handleChange("manufacturerName", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        item.manufacturerName
                      )}
                    </td>
                    <td className="col-1 text-center">
                      {editItemId === item.id ? (
                        <input
                          type="number"
                          value={editData.unitNumber || item.unitNumber}
                          onChange={(e) => {
                            handleChange("unitNumber", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        item.unitNumber
                      )}
                    </td>
                    <td className="col-1 text-center">
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editData.location || item.location}
                          onChange={(e) => {
                            handleChange("location", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        item.location
                      )}
                    </td>
                    <td className="col-1 text-center">
                      {editItemId === item.id ? (
                        <input
                          type="text"
                          value={editData.country || item.country}
                          onChange={(e) => {
                            handleChange("country", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        item.country
                      )}
                    </td>
                    <td className="col-1 text-center">
                      {editItemId === item.id ? (
                        <input
                          type="date"
                          defaultValue={
                            formatDate(editData.dateOfAudit) ||
                            formatDate(item.dateOfAudit)
                          }
                          onChange={(e) => {
                            handleChange("dateOfAudit", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        new Date(item.dateOfAudit).toLocaleDateString()
                      )}
                    </td>
                    <td className="col-1 text-center">
                      {editItemId === item.id ? (
                        <input
                          type="date"
                          defaultValue={
                            formatDate(editData.reportValidTill) ||
                            formatDate(item.reportValidTill)
                          }
                          onChange={(e) => {
                            handleChange("reportValidTill", e.target.value);
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        new Date(item.reportValidTill).toLocaleDateString()
                      )}
                    </td>
                    {/* <td className="col-1 text-center">
                        {new Date(item.dateOfAudit).toLocaleDateString()}
                      </td>
                      <td className="col-1 text-center">
                        {new Date(item.reportValidTill).toLocaleDateString()}
                      </td> */}
                    {/* <td className="col-1 text-center">
                      <i className="bi bi-download text-danger" style={{ cursor: "pointer" }} onClick={() => downloadReport()}></i>{" "}
                    </td> */}
                    <td className="col-2 text-center">
                      {/* {editItemId === item.id ? (
                        // Render save button if the item is in edit mode
                        <i className="bi bi-check me-4" style={{ cursor: "pointer" }}
                          onClick={() => handleSave(item.id)}></i>
                      ) : ( */}

                        <i className="bi bi-pencil me-4 text-warning" style={{ cursor: "pointer" }}
                          onClick={() => handleEdit(item)}></i>
                      {/* // )} */}

                      <i className="bi bi-trash text-danger" style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item.id)}></i>{" "}
                      {/* Icon for Delete */}

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1>No Reports Published so far</h1>
          )}

        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${pageNum === currentPage ? "active" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${currentPage === totalPages ? "disabled" : ""
                }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Modal for Edit */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Audit Report</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Product Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.productName || ""}
                    onChange={(e) => handleChange("productName", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Manufacturer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.manufacturerName || ""}
                    onChange={(e) => handleChange("manufacturerName", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Unit Number</label>
                  <input
                    type="number"
                    className="form-control"
                    value={editData.unitNumber || ""}
                    onChange={(e) => handleChange("unitNumber", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Location</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editData.location || ""}
                    onChange={(e) => handleChange("location", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <select
                    id="country"
                    name="country"
                    className="form-control"
                    required
                    value={editData.country || ""}
                    onChange={(e) => handleChange("country", e.target.value)}
                    style={{ appearance: "auto" }}
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Date of Audit</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formatDate(editData.dateOfAudit) || ""}
                    onChange={(e) => handleChange("dateOfAudit", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Report Valid Till</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formatDate(editData.reportValidTill) || ""}
                    onChange={(e) => handleChange("reportValidTill", e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublishedAuditReports;
