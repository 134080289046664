import axios from "axios";
import API_URL from "../config/api";

export const addCouponService = (coupon) => {
    return axios
    .post(API_URL.ADD_COUPON,coupon)
    .then((res) => {
        
        return res.data
        })
    .catch((err) => {
        const errorMessage = 
        err?.response?.data?.message || "Adding Coupon failed";
        return Promise.reject(errorMessage);
    });
};

export const fetchAllCoupons = () => {
    return axios
    .get(API_URL.GET_COUPON)
    .then((res) => {
        console.log("Coupons fetched successfully", res.data.data);
        return res.data.data
    })
    .catch((err) => {
        const errorMessage = 
        err?.response?.data?.message || "Fetch Coupons failed";
        return Promise.reject(errorMessage);
    })
}

export const fetchCouponByName = (couponName) => {
  return axios
  .get(API_URL.GET_NAMEDCOUPON + couponName)
  .then((res) => {
      console.log("Coupon fetched successfully", res.data.data);
      return res.data.data
  })
  .catch((err) => {
      const errorMessage = 
      err?.response?.data?.message || "Fetch Coupon failed";
      return Promise.reject(errorMessage);
  })
}

export const updateCoupon = (data, itemId) => {
    return axios
      .put(API_URL.UPDATE_COUPON + itemId, { data })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message || "Coupon Update Failed";
        return Promise.reject(errorMessage);
      });
  };

export const deleteCouponById = (couponid) => {
    return axios
      .delete(API_URL.DELETE_COUPON + couponid)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message || "Coupon Deletion Failed";
        return Promise.reject(errorMessage);
      });
  };