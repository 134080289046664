import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ItemSearch from "./ItemSearch";
import PublishedAuditReports from "../subcomponents/PublishedAuditReports";
import InquiriesMade from "../subcomponents/InquiriesMade";
import ProfileMenuItems from "../subcomponents/profile-components/ProfileMenuItems";
import ProfileAboutItem from "../subcomponents/profile-components/ProfileAboutItem";
import ProfileEditItem from "../subcomponents/profile-components/ProfileEditItem";
import ProfileChangePasswordItem from "../subcomponents/profile-components/ProfileChangePasswordItem";
import ProfileSubscribedPlans from "../subcomponents/profile-components/ProfileSubscribedPlans";
import PublishYourAuditReport from "../subcomponents/PublishYourAuditReport";
import PublishNewPlan from "../subcomponents/PublishNewPlan";
import VendorReport from "./VendorReport";
import ProfileHeader from "../ProfileHeader";
import UserReport from "./UserReport";
import RegisteredUsersTable from "../subcomponents/RegisteredUsersTable";
import RegisteredVendorsTable from "../subcomponents/RegisteredVendorsTable";
import AddNewCoupon from "../subcomponents/profile-components/AddNewCoupon";

import { useEffect } from "react";
import { getAttributeInLocalStorageData } from "../../utils";
import { sendVerificationEmail } from "../../services/auth";
import PublishedPlans from "../subcomponents/PublishedPlans";
import { toast } from "react-toastify";
import {
  inquiriesMadeDatastats,
  vendorslist,
  userslist,
} from "../../services/analytics";
import AddNewAdmin from "../subcomponents/profile-components/AddNewAdmin";
import { downloadCurrentUserFileService } from "../../services/fileutility";
import { audtiReportUploadService } from "../../services/fileutility";
import AdminReport from "./AdminReport";
import { blockperson, unblockperson } from "../../services/auth";

const ProfilePage = ({ showBackground }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.hash || "#profile-overview"
  );

  const [inquiriesMadeData, setInquiriesMadeData] = useState([]);

  const [userDetails, setUserDetails] = useState();
  const [pic, setPic] = useState(
    "https://st2.depositphotos.com/11742109/48212/v/450/depositphotos_482126926-stock-illustration-gender-neutral-profile-avatar-front.jpg"
  );
  const [auditReport, setAuditReport] = useState(null);

  const [publishedReportCount, setPublishedReportCount] = useState(0);
  const [registeredUsersData, setRegisteredUsersData] = useState([]);
  const [registeredVendorsData, setRegisteredVendorsData] = useState([]);
  const userRole = getAttributeInLocalStorageData("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (activeTab) {
      document
        .querySelector('button[data-bs-target="#profile-overview"]')
        .click();
      const tabTrigger = document.querySelector(
        `button[data-bs-target="${activeTab}"]`
      );
      if (tabTrigger) {
        tabTrigger.click();
      }
    }
    if (getAttributeInLocalStorageData("role") === "admin") {
      getPersonList();
    }

    setUserDetails(getAttributeInLocalStorageData("data"));

    downloadCurrentUserFileService("image")
      .then((res) => {
        setPic(res.data);
        // setUserDetails({ ...userDetails, profileImage: res.data });
      })
      .catch((error) => {});

    if (getAttributeInLocalStorageData("role") === "user") {
      inquiriesMadeDatastats()
        .then((res) => {
          setInquiriesMadeData(res.data);
        })
        .catch((error) => {
          toast.error(error); // Handle the error
        });
    }
    const handleHashChange = () => {
      setActiveTab(location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [location.hash]);

  const handleVerifyEmail = () => {
    sendVerificationEmail(getAttributeInLocalStorageData("email"))
      .then((res) => {
        console.log(res.status + "res.status");
        if (res.status === true) {
          toast.success("Verification Email Sent Successfully");
        } else {
          navigate("/expired");
          toast.error("Verification Email Send Failed");
        }
      })
      .catch((error) => {
        navigate("/expired");
        toast.error(error);
      });
  };

  const handleProfileChange = (event) => {
    console.log("handle profile change " + event.target.files);
    const file = event.target.files[0];
    setAuditReport(file); // Update the profileImage state with the selected file
  };

  const handleAuditReportUploadSubmit = async (event) => {
    event.preventDefault();
    console.log("auditReport" + auditReport.name.replace(/\.[^/.]+$/, ""));
    audtiReportUploadService(auditReport)
      .then(() => {
        toast.success("Profile Uploaded Successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const warningMessage = () => (
    <div className="d-flex justify-content-center">
      <div className="col-sm-6 d-flex justify-content-around alert alert-danger border-3 rounded-3 mx-5 mt-3 mb-3">
        <h6 className="my-auto">Please verify your email address </h6>
        <button
          type="submit"
          className="btn btn-danger"
          onClick={handleVerifyEmail}
        >
          Verify Email
        </button>
      </div>
    </div>
  );

  const getPersonList = () => {
    vendorslist()
      .then((res) => {
        setRegisteredVendorsData(res.data);
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });

    userslist()
      .then((res) => {
        setRegisteredUsersData(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const blockpersonid = (id) => {
    blockperson(id)
      .then((res) => {
        toast.success("person blocked successfully");
        getPersonList();
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });
  };

  const unblockpersonid = (id) => {
    unblockperson(id)
      .then((res) => {
        toast.success("person unblocked successfully");
        getPersonList();
      })
      .catch((error) => {
        toast.error(error); // Handle the error
      });
  };

  const downloadReport = async () => {
    try {
      // Call your downloadCurrentUserFileService function to get the response
      const response = await downloadCurrentUserFileService("report");

      // Check if the response contains a valid URL
      if (response.status && response.data) {
        const downloadUrl = response.data;

        // Open the download URL in a new tab or window
        window.open(downloadUrl, "_blank");
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error downloading report:", error);
    }
  };

  return (
    <>
      <ProfileHeader showBackground={showBackground} />
      <section id="profilepage">
        <div className="container-fluid mt-5">
          {getAttributeInLocalStorageData("emailVerified") === false &&
            warningMessage()}
          <div className="row">
            <div className="col-md-12 ">
              <div className="">
                <div className="profile-card pt-4 d-flex align-items-center justify-content-center mb-3">
                  <div className="">
                    <img src={pic} alt="Profile" className="rounded-circle" />
                  </div>
                  <div className="ms-3">
                    <h2>{userDetails?.name}</h2>
                    <h3>{userDetails?.jobRole}</h3>
                  </div>
                  {/* <div className="social-links mt-2">
                    <a className="twitter">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a className="facebook">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a className="instagram">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a className="linkedin">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-body pt-3">
                  {/* <!-- Bordered Tabs --> */}
                  <ProfileMenuItems activeTab={activeTab} />
                  <div className="tab-content pt-2">
                    <ProfileAboutItem
                      name={userDetails?.name}
                      company={userDetails?.company}
                      jobRole={userDetails?.jobRole}
                      country={userDetails?.country}
                      contact={userDetails?.contact}
                      email={userDetails?.email}
                      username={userDetails?.username}
                      companyBrief={userDetails?.companyBrief}
                    />
                    <ProfileEditItem
                      name={userDetails?.name}
                      company={userDetails?.company}
                      jobRole={userDetails?.jobRole}
                      country={userDetails?.country}
                      contact={userDetails?.contact}
                      email={userDetails?.email}
                      companyBrief={userDetails?.companyBrief}
                    />
                    <ProfileChangePasswordItem />
                    {userRole === "user" && (
                      <div
                        className="tab-pane fade pt-3"
                        id="browse-audit-report"
                      >
                        <ItemSearch />
                      </div>
                    )}
                    <div className="tab-pane fade pt-3" id="my-stats">
                      <h5>
                        {userRole === "user" && (
                          // <Link to="/user/dashboard">Dashboard</Link>
                          <UserReport />
                        )}
                        {userRole === "admin" && (
                          // <Link to="/admin/dashboard">Dashboard</Link>
                          <AdminReport />
                        )}
                        {userRole === "vendor" && (
                          // <Link to="/vendor/dashboard">Dashboard</Link>
                          <VendorReport />
                        )}
                      </h5>
                    </div>
                    {userRole === "user" && (
                      <div className="row">
                        <div className="col-lg-12 mt-3">
                          <div className="card">
                            <div className="card-body">
                              <h4>Browse Audit Reports</h4>
                              <ItemSearch />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {userDetails?.role === "vendor" && (
                      <div className="tab-pane fade pt-3" id="my-plans">
                        <ProfileSubscribedPlans
                          credits={getAttributeInLocalStorageData("credits")}
                        />
                        <PublishedPlans />
                        {/* {getAttributeInLocalStorageData("credits") !== 0 ? (
                          <ProfileSubscribedPlans
                            credits={getAttributeInLocalStorageData("credits")}
                          />
                        ) : (
                          <PublishedPlans />
                        )} */}
                      </div>
                    )}
                    {userDetails?.role === "vendor" && (
                      <div
                        className="tab-pane fade pt-3"
                        id="upload-sample-audit-report"
                      >
                        <form onSubmit={handleAuditReportUploadSubmit}>
                          <div className="row mb-3">
                            <label
                              htmlFor="auditreport"
                              className="col-md-4 col-lg-3 col-form-label"
                            >
                              Upload Audit Report
                            </label>
                            <div className="col-md-8 col-lg-9">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="file"
                                  name="auditreport"
                                  accept="image/*, application/pdf" // Accept both image and PDF
                                  style={{ display: "none" }}
                                  onChange={handleProfileChange}
                                  id="auditreportInput"
                                />
                                <label
                                  htmlFor="auditreportInput"
                                  className="btn btn-danger btn-sm"
                                  title="Upload new Audit Report"
                                >
                                  <i
                                    className="bi bi-upload"
                                    style={{ color: "white" }}
                                  ></i>
                                </label>
                                {auditReport?.name ? (
                                  <span
                                    className="ms-2"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {auditReport.name}
                                  </span>
                                ) : (
                                  <span className="ms-2">No file selected</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="text-center mb-3">
                            <button type="submit" className="btn btn-primary">
                              Upload Audit Report
                            </button>
                          </div>
                        </form>
                        <div>
                          <label
                            htmlFor="auditreport"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Download Audit Report
                          </label>
                          <label
                            htmlFor="auditreportdownload"
                            className="btn btn-danger btn-sm"
                            title="Dowbload Audit Report"
                          >
                            <i
                              className="bi bi-download"
                              style={{ color: "white", cursor: "pointer" }}
                              onClick={() => downloadReport()}
                            ></i>
                          </label>
                        </div>
                      </div>
                    )}

                    {userDetails?.role.toLowerCase() === "admin" && (
                      <div className="tab-pane fade pt-3" id="publish-plans">
                        <PublishedPlans />
                        <PublishNewPlan />
                      </div>
                    )}
                    {userDetails?.role.toLowerCase() === "admin" && (
                      <div className="tab-pane fade pt-3" id="add-admin">
                        <AddNewAdmin />
                      </div>
                    )}

                    {userDetails?.role.toLowerCase() === "admin" && (
                      <div className="tab-pane fade pt-3" id="block-unblock">
                        <RegisteredUsersTable
                          registeredUsersData={registeredUsersData}
                          blockpersonid={blockpersonid}
                          unblockpersonid={unblockpersonid}
                        />
                        <RegisteredVendorsTable
                          registeredVendorsData={registeredVendorsData}
                          blockpersonid={blockpersonid}
                          unblockpersonid={unblockpersonid}
                        />
                      </div>
                    )}

                    {userDetails?.role.toLowerCase() === "admin" && (
                      <div className="tab-pane fade pt-3" id="coupon">
                        <AddNewCoupon />
                      </div>
                    )}
                  </div>
                  {/* <!-- End Bordered Tabs --> */}
                </div>
              </div>
            </div>
          </div>
          {userDetails?.role === "vendor" && (
            <div className="row ">
              <div className="col-lg-12 col-sm-12 mb-4 mt-5 mt-lg-0">
                <PublishYourAuditReport
                  setPublishedReportCount={setPublishedReportCount}
                  publishedReportCount={publishedReportCount}
                />
              </div>
            </div>
          )}
          {userDetails?.role === "vendor" && (
            <div className="row">
              <div className="col-lg-12">
                <PublishedAuditReports
                  publishedReportCount={publishedReportCount}
                />
              </div>
            </div>
          )}
          {userDetails?.role === "user" && (
            <div className="row">
              <div className="col-lg-12 ">
                <InquiriesMade inquiriesMadeData={inquiriesMadeData} />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ProfilePage;
