import React, { useState } from "react";
import TableWithPagination from "../subcomponents/TableWithPagination";
import FilterForm from "../subcomponents/FilterForm";
import { searchItems } from "../../services/item";
import { toast } from "react-toastify";
import { useEffect } from "react";

function ItemSearch() {
  const [filteredData, setFilteredData] = useState([]);

  // Callback function to handle filtering
  const handleFilter = (filterValues) => {
    let data = {
      productname: filterValues.productname?.toLowerCase() ?? filterValues.productname,
      manufacturename: filterValues.manufacturename?.toLowerCase() ?? filterValues.manufacturename,
      country: filterValues.country.toLowerCase(),
    };

    performSearch(data);
  };

  const performSearch = (searchData) => {
    searchItems(searchData)
      .then((res) => {
        console.log(
          JSON.stringify(res.data) + "response from backend for itemsearch"
        );
        if (res.data.length === 0) {
          toast.error("No Audit Reports Found");
        } else {
          setFilteredData(res.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    performSearch({ productname: undefined, manufacturename: undefined, country: "" });
  }, []);

  return (
    <>
      <div id="itemsearch" className="">
        <FilterForm onFilter={handleFilter} />
        <TableWithPagination data={filteredData} />
        {/* <FetchItems /> */}
      </div>
    </>
  );
}

export default ItemSearch;
