import React, { useState } from "react";
import countries from "../../data/Countries";

const FilterForm = ({ onFilter }) => {
  const [filterValues, setFilterValues] = useState({
    productname: undefined,
    manufacturename: undefined,
    country: "",
  });

  const [errors, setErrors] = useState({
    country: false,
    productOrManufacturer: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      const selectedCountry = countries.find(
        (country) => country.code === value
      );
      setFilterValues((prevValues) => ({
        ...prevValues,
        country: selectedCountry ? selectedCountry.code : "",
      }));
    }else{
    setFilterValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }
  };

  const validateForm = () => {
    const isCountryValid = filterValues.country.trim() !== "";
    const isProductOrManufacturerValid = 
    (filterValues.productname?.trim() ?? "") !== "" ||
    (filterValues.manufacturename?.trim() ?? "") !== "";

    setErrors({
      country: !isCountryValid,
      productOrManufacturer: !isProductOrManufacturerValid,
    });

    return isCountryValid && isProductOrManufacturerValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      
      if (filterValues.productname === "") {
        filterValues.productname = undefined;
      }
      if (filterValues.manufacturename === "") {
        filterValues.manufacturename = undefined;
      }
      onFilter(filterValues);
    }
  };

  return (
    <section id="filterform" className="container-fluid my-4 p-0">
      <div className="card p-3 0 my-3 mx-2">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="productname">Product Name</label>
              <input
                type="text"
                className="form-control"
                id="productname"
                name="productname"
                value={filterValues.productname}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="manufacturename">Manufacture Name</label>
              <input
                type="text"
                className="form-control"
                id="manufacturename"
                name="manufacturename"
                value={filterValues.manufacturename}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="country">Country</label>
              {/* <input
                type="text"
                className="form-control"
                id="country"
                name="country"
                value={filterValues.country}
                onChange={handleChange}
              /> */}
              <select
                id="country"
                name="country"
                className={`form-control ${errors.country ? "is-invalid" : ""}`}
                value={filterValues.country}
                onChange={handleChange}
                style={{ appearance: 'auto' }}
              >

                <option value="" disabled>
                  Select Country
                </option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
                {errors.country && (
                <div className="invalid-feedback">Country is required.</div>
              )}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
            
              {errors.productOrManufacturer && (
                <div className="text-danger mb-2">
                  Please fill in at least one of the fields: Product Name or
                  Manufacture Name.
                </div>
              )}
              <button type="submit" className="btn btn-danger">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default FilterForm;
