import React from "react";
import { getAttributeInLocalStorageData } from "../../../utils";

function ProfileMenuItems({ activeTab }) {
  const userRole = getAttributeInLocalStorageData("role");

  return (
    <ul className="nav nav-tabs nav-tabs-bordered">
      <li className="nav-item ">
        <button
          className={`nav-link nav-link-profile ${activeTab === "#profile-overview" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#profile-overview"
        >
          <strong>Overview</strong>
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link nav-link-profile ${activeTab === "#profile-edit" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#profile-edit"
        >
          <strong>Edit Profile</strong>
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link nav-link-profile ${activeTab === "#profile-change-password" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#profile-change-password"
        >
          <strong>Change Password</strong>
        </button>
      </li>
      {/* {userRole === "user" && (
        <li className="nav-item">
          <button
            className={`nav-link nav-link-profile ${activeTab === "#browse-audit-report" ? "active" : ""}`}
            data-bs-toggle="tab"
            data-bs-target="#browse-audit-report"
          >
            <strong>Browse Audit Report</strong>
          </button>
        </li>
      )} */}
      <li className="nav-item">
        <button
          className={`nav-link nav-link-profile ${activeTab === "#my-stats" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#my-stats"
        >
          <strong>My Stats</strong>
        </button>
      </li>
      {userRole === "vendor" && (
        <li className="nav-item">
          <button
            className={`nav-link nav-link-profile ${activeTab === "#upload-sample-audit-report" ? "active" : ""}`}
            data-bs-toggle="tab"
            data-bs-target="#upload-sample-audit-report"
          >
            <strong>Sample Audit Report</strong>
          </button>
        </li>
      )}
      {userRole === "vendor" && (
        <li className="nav-item">
          <button
            className={`nav-link nav-link-profile ${activeTab === "#my-plans" ? "active" : ""}`}
            data-bs-toggle="tab"
            data-bs-target="#my-plans"
          >
            <strong>Plans</strong>
          </button>
        </li>
      )}
      {userRole === "admin" && (
        <li className="nav-item">
          <button
            className={`nav-link nav-link-profile ${activeTab === "#add-admin" ? "active" : ""}`}
            data-bs-toggle="tab"
            data-bs-target="#add-admin"
          >
            <strong>Add New Admin</strong>
          </button>
        </li>
      )}
      {userRole === "admin" && (
        <li className="nav-item">
          <button
            className={`nav-link nav-link-profile ${activeTab === "#block-unblock" ? "active" : ""}`}
            data-bs-toggle="tab"
            data-bs-target="#block-unblock"
          >
            <strong>Block / Unblock</strong>
          </button>
        </li>
      )}
      {userRole === "admin" && (
        <li className="nav-item">
          <button
            className={`nav-link nav-link-profile ${activeTab === "#coupon" ? "active" : ""}`}
            data-bs-toggle="tab"
            data-bs-target="#coupon"
          >
            <strong>Coupons</strong>
          </button>
        </li>
      )}
    </ul>
  );
}

export default ProfileMenuItems;
