


const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script);
    })
  }

//   const createRazorpayOrder = (amount) => {
//     let data = JSON.stringify({
//       amount: amount * 100,
//       currency: "INR"
//     })

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: "http://localhost:5000/orders",
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       data: data
//     }

//     axios.request(config)
//     .then((response) => {
//       console.log(JSON.stringify(response.data))
//       handleRazorpayScreen(response.data.amount)
//     })
//     .catch((error) => {
//       console.log("error at", error)
//     })
//   }

  export const handleRazorpayScreen = async(orderId,amount,currency) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")

    if (!res) {
      alert("Some error at razorpay screen loading")
      return;
    }

    const options = {
      key: 'rzp_test_gIFHNX6Lcrw1lL',
      amount: amount,
      currency: currency,
      name: "GMP Audit Report Order",
      description: "Request by one of the vendor of GMP Audit Report",
      image: "https://papayacoders.com/demo.png",
      order_id: orderId,
    //   handler: function (response){
    //     setResponseId(response.razorpay_payment_id)
    //   },
    //   prefill: {
    //     name: "papaya coders",
    //     email: "papayacoders@gmail.com"
    //   },
      theme: {
        color: "#F4C430"
      }
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }